// Vendor  node_modules jQuery
import jQuery from 'jquery';
window.$ = window.jQuery = jQuery;

require('@fancyapps/fancybox');
 
$("[data-fancybox='video-autologus']").fancybox({
  width: 1080,
  height: 1080,
  aspectRatio: false,
  video: {
    autoStart: true,
  },
});

 


require('../../node_modules/flickity/dist/flickity.pkgd.min.js');
require('../../node_modules/slick-carousel/slick/slick.min.js');

// require('gsap');

// Scripts
require('./modules/scripts');

var AOS = require('aos');

// https://michalsnik.github.io/aos/
AOS.init({duration:800,easing:'slide',once:true});

import { CountUp } from 'countup.js';
const options = {
  separator: '.',
 duration: 5.2,
};
function counters(){


if (document.getElementById('myTargetElement1')) {
  var var1 = document.getElementById('myTargetElement1');
  var var1_text = var1.innerText;
  var var1_number = var1_text.replaceAll('.', '');
  let demo1 = new CountUp('myTargetElement1', var1_number , options);
  if (!demo1.error) {
    demo1.start();
    $('.c-info__item-counter').addClass('is-active');
  } else {
    console.error(demo1.error);
  }
}
if (document.getElementById('myTargetElement2')) {
  var var2 = document.getElementById('myTargetElement2');
  var var2_text = var2.innerText;
  var var2_number = var2_text.replaceAll('.', '');
  // var var2_number = parseInt( var2_text );
  let demo2 = new CountUp('myTargetElement2', var2_number , options);
  if (!demo2.error) {
    demo2.start();
    $('.c-info__item-counter').addClass('is-active');
  } else {
    console.error(demo2.error);
  }
}
if (document.getElementById('myTargetElement3')) {
  var var3 = document.getElementById('myTargetElement3');
  var var3_text = var3.innerText;
  var var3_number = var3_text.replaceAll('.', '');
  // var var3_number = parseInt( var3_text );
  let demo3 = new CountUp('myTargetElement3', var3_number , options);
  if (!demo3.error) {
    demo3.start();
    $('.c-info__item-counter').addClass('is-active');
  } else {
    console.error(demo3.error);
  }
}
}


var loop_count = 0;
if (document.getElementById('checkpoint-counter')){

    var scrollLeft = window.pageXOffset || document.documentElement.scrollLeft,
    scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    var element = document.getElementById('checkpoint-counter');
    var elementHeader = document.getElementById('header');
    var js_apper = document.getElementById("checkpoint-counter");
    var offset = js_apper.getBoundingClientRect().top;

    var innerHeight = window.innerHeight;

    // console.log(elementHeader.clientHeight + ' : ' + innerHeight + ' : ' + offset + ' : ' + scrollTop);
    console.log(innerHeight - (offset+100) + ' : ' + scrollTop);

    if (innerHeight - (offset+100)  > 0 ) {
      loop_count++;
      if (loop_count==1){
      // console.log( ' + ' . loop_count);
      counters();
      }
    }

  window.addEventListener('scroll', function(){
    var scrollLeft = window.pageXOffset || document.documentElement.scrollLeft,
    scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    var element = document.getElementById('checkpoint-counter');
    var elementHeader = document.getElementById('header');
    var js_apper = document.getElementById("checkpoint-counter");
    var offset = js_apper.getBoundingClientRect().top;
    var innerHeight = window.innerHeight;
    //console.log(offset - innerHeight);
    

     if (innerHeight - (offset+100)  > 0 ) {
      loop_count++;
      if (loop_count==1){
      // console.log( ' + ' . loop_count);
      counters();
      }
    }
  });
}





	









